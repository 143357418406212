export const PRODUCT_TYPES = {
  DIGITAL: "DIGITAL",
  PHYSICAL: "PHYSICAL",
  DROP: "PHYSICAL_DROP",
};

export const DROP_PRODUCT_STATUSES = {
  ENDED: "ENDED",
  LAUNCHING: "LAUNCHING",
  SALING: "SALING",
};

export const PRODUCT_SHIPPING_TYPES = {
  FIXED_PRICE: "FIXED_PRICE",
  FREE: "FREE",
};
