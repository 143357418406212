import PropTypes from "prop-types";
import purple from "@material-ui/core/colors/purple";
import { FulfillingBouncingCircleSpinner } from "react-epic-spinners";
import { SpinnerWrapper } from "../../site-pages/styles";

const Loading = ({ color }) => (
  <SpinnerWrapper padding>
    <FulfillingBouncingCircleSpinner color={color} size={30} />
  </SpinnerWrapper>
);

Loading.defaultProps = {
  color: purple[500],
};

Loading.propTypes = {
  color: PropTypes.string,
};

export default Loading;
