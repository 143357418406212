import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { useCallback } from "react";

const useCardElementRequest = () => {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmitCardElement = useCallback(
    async (clientSecret, onSuccess, onError) => {
      if (!stripe || !elements) {
        // Stripe.js has not loaded yet. Make sure to disable
        // form submission until Stripe.js has loaded.
        return;
      }

      stripe
        .confirmCardPayment(clientSecret, {
          payment_method: {
            card: elements.getElement(CardElement),
          },
        })
        .then((result) => {
          if (result.error) {
            if (onError && typeof onError === "function") {
              onError(result.error);
            }
          } else if (result.paymentIntent.status === "succeeded") {
            if (onSuccess && typeof onSuccess === "function") {
              onSuccess();
            }
          }
        });
    },
    [stripe, elements]
  );

  return {
    handleSubmitCardElement,
  };
};

export default useCardElementRequest;
