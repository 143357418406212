import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import PropTypes from "prop-types";
import useCardElementRequest from "./useCardElementRequest";
import { PRODUCT_TYPES } from "../constants/products";

export const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const [account, setAccount] = useState(null);
  const [cart, setCart] = useState([]);
  const [cartLength, setCartLength] = useState(0);
  const [shippingPrice, setShippingPrice] = useState(0);

  const [openCart, setOpenCart] = useState(false);
  const toggleCart = () => {
    setOpenCart((isOpen) => !isOpen);
  };

  useEffect(() => {
    const all = Object.values(cart).reduce((sum, curr) => sum + curr.length, 0);
    setCartLength(all);
  }, [cart]);

  const removeItemFromCart = useCallback((category, itemId) => {
    setCart((data) => {
      let curData = { ...data };
      const items = data[category].filter((i) => i.item.id !== itemId);
      if (items.length > 0) {
        curData = { ...data, [category]: items };
      } else {
        delete curData[category];
      }
      return { ...curData };
    });
  }, []);

  const addToCart = useCallback(
    (category, item, options) => {
      const thisCategory = category || "other";
      setCart((data) => {
        const currentItems = data[thisCategory] || [];
        currentItems.push({ item, amount: options?.amount || 1, options });
        return { ...data, [thisCategory]: currentItems };
      });
    },
    [setCart]
  );

  const emptyCart = useCallback(() => {
    setCart([]);
  }, [setCart]);

  const getSubtotal = useCallback(
    (items) =>
      items.reduce((acc, cur) => {
        const isDigital = cur.item?.type === PRODUCT_TYPES.DIGITAL;
        const price = cur.item?.priceCents || 0;
        const amount = isDigital ? 1 : cur.amount || 0;
        return acc + +(amount * price) / 100;
      }, 0),
    []
  );

  const getTotalPrice = useCallback(() => {
    const productPrice = Object.values(cart).reduce(
      (a, c) => a + getSubtotal(c),
      0
    );
    const shipPrice = +shippingPrice / 100;
    return productPrice + (shipPrice || 0);
  }, [cart, getSubtotal, shippingPrice]);

  const getSources = useCallback(() => {
    const sources = {};
    Object.values(cart).forEach((cartItems) => {
      cartItems.forEach(({ item }) => {
        const { source } = item;
        if (source) {
          sources[source.id] = { ...source };
        }
      });
    });
    return sources;
  }, [cart]);

  const { handleSubmitCardElement } = useCardElementRequest();

  const contextData = useMemo(
    () => ({
      cart,
      cartLength,
      addToCart,
      removeItemFromCart,
      account,
      setAccount,
      getSubtotal,
      getTotalPrice,
      shippingPrice,
      setShippingPrice,

      openCart,
      toggleCart,
      emptyCart,

      getSources,
      handleSubmitCardElement,
    }),
    [
      account,
      addToCart,
      cart,
      cartLength,
      emptyCart,
      getSources,
      getSubtotal,
      getTotalPrice,
      handleSubmitCardElement,
      openCart,
      removeItemFromCart,
      shippingPrice,
    ]
  );

  return (
    <AppContext.Provider value={contextData}>{children}</AppContext.Provider>
  );
};

AppProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
