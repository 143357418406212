import { useEffect } from "react";
import Router, { useRouter } from "next/router";

import PropTypes from "prop-types";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import ProgressBar from "@badrap/bar-of-progress";

import { AppProvider } from "../site-pages/context";
import DefaultLayout from "../layouts/DefaultLayout";
// import GoogleAnalytics from '../components/GoogleAnalytics';

import "../styles/globals.css";
import "react-phone-number-input/style.css";
import Loading from "../components/Loading";
import { StyledBackdropArea } from "../components/styles/layout";

const progress = new ProgressBar({
  size: 3,
  color: "#8e24aa",
  className: "bar-of-progress",
  delay: 100,
});

Router.events.on("routeChangeStart", progress.start);
Router.events.on("routeChangeComplete", progress.finish);
Router.events.on("routeChangeError", progress.finish);

const stripePromise = loadStripe(
  process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY
);
// const isDevelopment = process.env.NODE_ENV === 'development';

const MyApp = ({ Component, pageProps }) => {
  const router = useRouter();

  const Layout = Component.Layout || DefaultLayout;
  const layoutProps = pageProps.pageData;

  useEffect(() => {
    const handleRouteChange = () => {
      document.getElementById("spinner").style.display = "flex";
    };

    const handleRouteComplete = () => {
      document.getElementById("spinner").style.display = "none";
    };

    router.events.on("routeChangeStart", handleRouteChange);
    router.events.on("routeChangeComplete", handleRouteComplete); // If the component is unmounted, unsubscribe

    // from the event with the `off` method:
    return () => {
      router.events.off("routeChangeStart", handleRouteChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    import("react-facebook-pixel")
      .then((x) => x.default)
      .then((ReactPixel) => {
        ReactPixel.init("1040585706520097"); // facebookPixelId
        ReactPixel.pageView();

        router.events.on("routeChangeComplete", () => {
          ReactPixel.pageView();
        });
      });
  }, [router.events]);

  return (
    <Elements stripe={stripePromise}>
      <AppProvider>
        <Layout {...layoutProps}>
          <StyledBackdropArea id="spinner">
            <Loading />
          </StyledBackdropArea>
          <Component {...pageProps} />
        </Layout>
      </AppProvider>
    </Elements>
  );
};

MyApp.propTypes = {
  Component: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.string,
    PropTypes.element,
  ]),
  pageProps: PropTypes.shape(),
};

MyApp.defaultProps = {
  Component: <div />,
  pageProps: {},
};

export default MyApp;
