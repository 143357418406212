import styled, { css } from "styled-components";
import { styled as materialStyles } from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
import { grey } from "@material-ui/core/colors";
import color from "./colors";

export const FlexGrid = materialStyles(Grid)({
  display: "flex",
  flexFlow: "column nowrap",
  boxSizing: "border-box",
});

export const StyledBackdropArea = styled.div`
  display: none;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 9999;
`;

export const FlexFlow = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.column ? "column" : "row")};
  flex-wrap: ${(props) => (props.nowrap ? "nowrap" : "wrap")};
  flex-grow: ${(props) => (props.grow ? "1" : "0")};
  flex-shrink: ${(props) => (props.shrink ? "1" : "0")};
  flex-basis: ${(props) => props.basis || "auto"};
  margin: ${(props) => props.margin || "0"};
  padding: ${(props) => props.padding || "0"};
  align-items: ${(props) => props.align || "stretch"};
  justify-content: ${(props) => props.justify || "stretch"};
  gap: ${(props) => props.splitSpace || 0};

  ${(props) =>
    props.bordered &&
    css`
      border-color: ${grey[100]};
      border-width: 1px 0;
      border-style: solid;
    `};
`;

export const BasePageWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  flex: 1 0 auto;
  background: linear-gradient(90deg, #149338 0%, #376d85 100%);
  height: fit-content;
  z-index: 1;
`;

export const ComposeDialogWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  flex: 1 0 auto;
  background: #f9f9fb;
  height: fit-content;
  z-index: 1;
`;

export const BaseDashboardPageWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  flex: 1 0 auto;
  background: #f9f9fb;
  height: fit-content;
  z-index: 1;
`;

export const BaseVenuePageContent = styled.div`
  display: flex;
  flex-flow: column nowrap;
  flex: 1 1 auto;
  background-color: ${(props) =>
    props.bgColor ? props.bgColor : color.venueBgColor};
  padding: 1em;
  height: fit-content;
  ${(props) =>
    props.noPadding &&
    css`
      padding: 0em;
    `}
`;

export const BaseVenuePageSection = styled.div`
  display: block;
  border-bottom: 1px solid rgba(255, 255, 255, 0.39);
  color: inherit;
  max-width: calc(100vw);
  padding: 1rem 0;
  ${(props) =>
    props.noBorder &&
    css`
      border-bottom: none;
      padding-bottom: 0;
    `}
`;

export const BodyWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  flex: 1 1 auto;
  background-color: ${grey[50]};
  max-height: 100vh;
  overflow: hidden;
`;

export const PageWrapper = styled.div`
  background-color: #ffffff;
  display: flex;
  flex-flow: row nowrap;
  flex: 1 1 auto;
  overflow: hidden;
  padding: 0;
  position: relative;
  transition: all 0.3s cubic-bezier(0.05, 0.74, 0.27, 0.99);
  ${(props) =>
    props.isSidebarOpen &&
    css`
      margin-right: 345px;
      overflow: hidden;
    `}
  ${(props) =>
    !props.noMobile &&
    css`
      @media (max-width: 960px) {
        flex-flow: column nowrap;
      }
    `}
`;

export const SectionWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  flex: 1 1 auto;
  position: relative;
`;

export const ContentWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-flow: column nowrap;
  flex: 1 1 auto;
  padding: 1em 2em;
  overflow: hidden;
  ${(props) =>
    props.noGrow &&
    css`
      flex-grow: 0;
    `}
  ${(props) =>
    props.noShrink &&
    css`
      flex-shrink: 0;
    `}
  ${(props) =>
    props.noPadding &&
    css`
      padding: 0;
    `}
  ${(props) =>
    props.noPaddingTop &&
    css`
      padding: 0 1em;
    `}
  ${(props) =>
    props.noPaddingX &&
    css`
      padding-left: 0;
      padding-right: 0;
    `}
  ${(props) =>
    props.center &&
    css`
      align-items: center;
    `}
  
  @media (max-width: 960px) {
    flex: 1 1 auto;
  }
`;

export const DashCardWrapper = styled.div`
  background: white;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.04);
  border: 1px solid ${grey[200]};
  border-radius: 0.25rem;
  padding: 1.25rem;
  margin-bottom: 1em;
  a {
    text-decoration: none;
  }
  ${(props) =>
    props.stretch &&
    css`
      align-self: stretch;
      flex-grow: 1;
    `}
  ${(props) =>
    props.maxWidth &&
    css`
      max-width: ${props.maxWidth};
      @media (max-width: 960px) {
        max-width: 100%;
      }
    `}
`;

export const VisibleMdUp = styled.div`
  display: flex;
  flex: 0 1 auto;
  flex-flow: column nowrap;
  @media (max-width: 960px) {
    display: none;
  }
`;

export const HiddenSmUp = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: flex;
    flex: 0 1 auto;
    flex-flow: column nowrap;
  }
`;
