import styled, { css } from "styled-components";
import { grey, green, red, orange } from "@material-ui/core/colors";
import device from "../constants/mediaBreakPoints";

export const PageSectionWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  padding: 1rem 0 0;
`;

export const PageSectionTitle = styled.p`
  color: var(--text-color);
  text-transform: uppercase;
  font-size: 1rem;
  padding: 0 1rem;
  margin: 0 0 0.5rem;
`;

export const SpinnerWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  flex: 0 1 auto;
  align-items: center;
  justify-content: center;
  ${(props) =>
    props.small &&
    css`
      div,
      .circle,
      .orbit {
        height: 2em;
        width: 2em;
      }
    `};
  ${(props) =>
    props.padding &&
    css`
      padding-bottom: 0.5rem;
      padding-top: 0.5rem;
    `}
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  padding: 0.5em 1em;
  align-self: stretch;
  flex: 1 0 auto;
  overflow: hidden;
  position: relative;

  ${(props) =>
    props.borderTop &&
    css`
      border-top: 1px solid var(--border-color);
      padding-top: 1rem;
    `}

  ${(props) =>
    props.nopadding &&
    css`
      padding: 0;
    `}
`;

export const SectionWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  margin: 1rem 0;

  ${(props) =>
    props.borderBottom &&
    css`
      border-bottom: 1px solid var(--border-color);
      padding-bottom: 1rem;
    `}
`;

export const PageWrapper = styled.div`
  background-color: var(--background-color);
  box-sizing: border-box;
  display: flex;
  flex: 1 0 auto;
  flex-flow: column nowrap;
  ${(props) =>
    props.scrollable &&
    css`
      height: calc(100%);
      overflow: auto;
    `}
  ${(props) =>
    props.dark &&
    css`
      --background-color: #000000;
      --background-color-rgb: 0, 0, 0;
      --background-secondary-color: ${grey[900]};
      --border-color: ${grey[900]};
      --border-dark-color: ${grey[800]};
      --button-text-color: ${grey[800]};
      --button-border-color: ${grey[800]};
      --button-border-dark-color: ${grey[400]};
      --card-bg-color: ${grey[900]};
      --event-item-bg-color: ${grey[800]};
      --text-color: ${grey[300]};
      --text-error-color: ${red[600]};
      --text-secondary-color: ${grey[400]};
    `}
`;

export const LPTitle = styled.p`
  color: var(--text-color);
  font-weight: 700;
  font-size: 1.2em;
  letter-spacing: 0.075em;
  line-height: 1.4;
  margin: 0 0 0.5rem 0;

  > span {
    margin-right: 0.5rem;
  }

  .thin {
    font-weight: 200;
  }

  ${(props) =>
    props.nomargin &&
    css`
      margin: 0;
    `}

  ${(props) =>
    props.badge &&
    css`
      margin: 0 0 0.2rem 1.6rem;
    `}
  
  ${(props) =>
    props.small &&
    css`
      font-size: 0.9em;
    `}
  
   ${(props) =>
    props.upcase &&
    css`
      text-transform: uppercase;
    `}

   ${(props) =>
    props.center &&
    css`
      text-align: center;
    `}
   
   img {
    height: 16px;
    margin: 0 0 0 0.5rem;
    width: 16px;
  }
`;

export const LPSubtitle = styled.p`
  color: var(--text-color);
  font-size: 0.9em;
  font-weight: 800;
  line-height: 1.6;

  margin: 0;
  padding: 0.5em 1em;

  ${(props) =>
    props.spaced &&
    css`
      margin: 2rem 0;
    `}

  ${(props) =>
    props.centered &&
    css`
      align-self: center;
      text-align: center;
    `}

  ${(props) =>
    props.left &&
    css`
      align-self: flex-start;
      padding-left: 0;
    `}
  ${(props) =>
    props.nomargin &&
    css`
      margin: 0;
    `}

    ${(props) =>
    props.thin &&
    css`
      font-size: 1.1em;
      font-weight: 200;
      padding: 0;
    `}

  ${(props) =>
    props.midFontSize &&
    css`
      font-size: 1.2em;
    `}
  
  ${(props) =>
    props.lgFontSize &&
    css`
      font-size: 1.6em;
    `}
`;

export const LPUrlRedirectWrapper = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--border-color);
  margin: 0.5rem 0;
  padding: 0.5rem;
  width: 100%;
`;

export const LPText = styled.p`
  color: var(--text-secondary-color);
  font-weight: 400;
  font-size: 0.9em;
  line-height: 1.6;
  margin: 0;

  a {
    color: var(--text-secondary-color);
  }

  ${(props) =>
    props.w400 &&
    css`
      font-weight: 400;
    `}

  ${(props) =>
    props.w600 &&
    css`
      font-weight: 600;
    `}

  ${(props) =>
    props.w800 &&
    css`
      font-weight: 800;
    `}

  ${(props) =>
    props.mtauto &&
    css`
      margin-top: auto;
    `}

  ${(props) =>
    props.mb1 &&
    css`
      margin-bottom: 1em;
    `}

  ${(props) =>
    props.mb2 &&
    css`
      margin-bottom: 2em;
    `}

  ${(props) =>
    props.m05 &&
    css`
      margin: 0.5rem;
    `}

  ${(props) =>
    props.marginleft &&
    css`
      margin-left: auto;
    `}

  ${(props) =>
    props.marginright &&
    css`
      margin-right: auto;
    `}

  ${(props) =>
    props.lowercase &&
    css`
      text-transform: lowercase;
    `}

  ${(props) =>
    props.nomargin &&
    css`
      margin-bottom: 0;
    `}

  ${(props) =>
    props.padded &&
    css`
      padding: 0.5em 1em;
    `}

  ${(props) =>
    props.centered &&
    css`
      text-align: center;
    `};

  ${(props) =>
    props.small &&
    css`
      font-size: 0.8em;
    `};

  ${(props) =>
    props.tiny &&
    css`
      font-size: 0.6em;
    `};

  ${(props) =>
    props.description &&
    css`
      font-size: 0.8em;
      margin-bottom: 0.5em;
      color: ${grey[400]};
    `};

  ${(props) =>
    props.error &&
    css`
      background-color: ${props.clear ? "transparent" : red[600]};
      border-radius: 0.25em;
      color: ${props.clear ? red[600] : grey[100]};
      padding: 0.5em 1em;
    `}

  ${(props) =>
    props.warning &&
    css`
      background-color: ${props.clear ? "transparent" : orange[600]};
      border-radius: 0.25em;
      color: ${props.clear ? orange[600] : grey[100]};
      padding: 0.5em 1em;
    `}

  ${(props) =>
    props.success &&
    css`
      background-color: ${props.clear ? "transparent" : green[600]};
      border-radius: 0.25em;
      color: ${props.clear ? green[600] : grey[100]};
      padding: 0.5em 1em;
    `}

  ${(props) =>
    props.active &&
    css`
      color: var(--brand-color);
    `}
`;

export const LPLink = styled.a`
  text-decoration: none;
  ${(props) =>
    props.bottom &&
    css`
      margin-top: auto;
    `}
`;

export const LPImageWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  flex: 1 1 60%;
  padding: 0 2rem;
  img {
    border: 1px solid ${grey[50]};
    object-fit: contain;
    width: 100%;
  }
  ${(props) =>
    props.small &&
    css`
      flex: 0 0 auto;
      height: 210px;
      margin: 1em 0;
      padding: 0;
      overflow: hidden;
      img {
        object-fit: cover;
      }
    `}
  @media screen and ${device.tablet} {
    order: 0;
    padding: 0 0 2rem;
  }
  @media screen and ${device.mobileL} {
    order: 0;
    padding: 0;
    height: auto;
  }
`;

export const NavBar = styled.div`
  display: grid;
  align-items: center;
  justify-items: center;
  grid-template-columns: 80px 1fr 80px;
  grid-template-rows: 1fr;
  padding: 1em;
  .sc_logo {
    height: 30px;
  }
`;

export const CardTextArea = styled.div`
  display: flex;
  flex-flow: column nowrap;
  flex: 0 1 auto;
  align-self: stretch;
  color: ${grey[500]};
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.4;
  ${(props) =>
    props.padded &&
    css`
      padding: 0.5em 1em;
    `}
`;

export const BodyWrapper = styled.div`
  display: flex;
  flex-flow: column;
  flex: 1 0 auto;
  padding: 0 1em;
  background-color: var(--background-color);
  z-index: 1;
`;

export const HighlightItem = styled.div`
  display: flex;
  align-items: center;
  flex-flow: row nowrap;
  flex: 1 0 auto;
  border-bottom: 1px solid var(--border-color);
  padding: 1rem 0;

  &:first-child {
    border-top: 1px solid var(--border-color);
  }

  .icon_start {
    flex: 0 0 auto;
    color: var(--brand-color);
    font-size: 2.5em;
    margin-right: 1rem;
    padding: 0 0.5rem;
  }
  .icon_end {
    color: var(--text-color);
  }
`;

export const HighlightItemContent = styled.div`
  display: flex;
  flex-flow: column nowrap;
  flex: 1 1 auto;
  color: var(--brand-color);
`;

export const HighlightItemText = styled.p`
  color: var(--text-color);
  font-size: 0.8em;
  font-weight: 400;
  line-height: 1.4;
  margin: 0;
  & + & {
    margin-top: 0.25em;
  }
  ${(props) =>
    props.answer &&
    css`
      font-weight: 400;
      margin-bottom: 1em;
    `}
`;

export const HighlightItemTitle = styled(HighlightItemText)`
  font-size: 1em;
  font-weight: 400;
`;

export const HighlightItemLabel = styled(HighlightItemText)`
  font-size: 0.6em;
  font-weight: 300;
  text-transform: uppercase;
`;
